* {
  box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif;
  margin: 0;
}

input,
select,
textarea {
  border: 1px solid #ccc;
  padding: 5px;
  width: 250px;
  line-height: 25px;
  border-radius: 5px;
}

.tab {
  float: left;
  border: 1px solid #ccc;
  width: 20%;
  height: 100vh;
  text-align: center;
}

.tab div {
  display: block;
  color: black;
  background-color: #ddd;
  padding: 22px 16px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
  border-bottom: 1px solid #c3c3c3;
}

.tab div:hover {
  background-color: #f1f1f1;
}

.tab div.active {
  background-color: #fff;
}

.tabcontent {
  float: left;
  padding: 0px 12px;
  width: 80%;
  box-shadow: 0 0 20px rgb(66 50 98 / 35%);
 /*  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll; */
}

.container {
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 10vh);
}

.col {
  float: left;
  width: 50%;
  background: #eaeaea;
  text-align: center;
  border: 15px solid White;
  padding-top: 5%;
  padding-bottom: 5%;
  font-size: 30px;
}

.heading {
  padding: 15px;
}

table {
  width: 100%;
}

.mapEA {
  width: auto;
  height: 400px;
}

.boxed {
  display: inline-block;
  padding: 10px;
  transition: all 0.3s;
  margin-left: 5px;
  cursor: pointer;
}

.boxed input[type='radio'] {
  display: none;
}

.boxed input[type='radio']:checked + label {
  border: solid 2px green;
}

label {
  display: block;
  padding: 5px;
  position: relative;
  padding-left: 20px;
}

.boxed span {
  border: solid 1px #ccc;
  padding: 10px;
  border-radius: 5px;
}

input:checked + span {
  border: solid 2px black;
  padding: 10px;
  color: white;
  background: #3f51b5;
}

button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background: blue;
  color: white;
  cursor: pointer;
}

.borderDiv {
  margin-top: 25px;
  
}

.toggle[type='checkbox'] {
  display: none;
}

.wrap-collabsible {
  margin: 1.2rem 0;
}

.lbl-toggle {
  display: block;
  font-weight: bold;
  font-family: monospace;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 1rem;
  cursor: pointer;
  border-radius: 7px;
  transition: all 0.25s ease-out;
}

.lbl-toggle::before {
  content: ' ';
  display: inline-block;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid currentColor;
  vertical-align: middle;
  margin-right: 0.7rem;
  transform: translateY(-2px);
  transition: transform 0.2s ease-out;
}

.toggle:checked + .lbl-toggle::before {
  transform: rotate(90deg) translateX(-3px);
}

.collapsible-content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
  max-height: 350px;
}

.toggle:checked + .lbl-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.collapsible-content .content-inner {
  border-radius: 7px;
  padding: 0.5rem 1rem;
}

.collapsible-content p {
  margin-bottom: 0;
}

.dataTableLink {
  background-color: #eeeeee;
  padding: 8px;
  border: 1px solid #c3c3c3;
  cursor: pointer;
  margin-right: 5px;
  text-decoration: none;
  color: black;
  border-radius: 5px;
}

.dataTableLink:hover {
  background-color: black;
  padding: 8px;
  border: 1px solid #c3c3c3;
  cursor: pointer;
  margin-right: 5px;
  text-decoration: none;
  color: #eeeeee;
  border-radius: 5px;
}

.container {
  padding: 25px;
  box-shadow: 0 0 20px rgba(66, 50, 98, 0.35);
  border: 1px solid #eaeaea;
  border-radius: 3px;
  background: white;
}

@-moz-document url-prefix() {
  .fileUploadInput button {
    display: none;
  }
}

/* .MuiTableCell-root {
  text-align: center !important;
} */

.errorMsg {
  color: red;
  margin-left: 2px;
}

.MuiTableContainer-root {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #3f51b5 !important;
}

.MuiTable-root {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.marker {
  background-image: url('https://static.wixstatic.com/media/394635_e75ecc6a5b6943ffa4055e43cc6733d8~mv2.png');
  background-size: cover;
  width: 24px;
  height: 37px;
  cursor: pointer;
}

.MUIDataTableHeadCell-contentWrapper-60 {
  align-items: center !important;
}
.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  width: 24%;
  display: inline-block;
  margin-top: 10px;
}

.submenu
{
  width: 95% !important;
  padding: 5px 16px !important;
}
.tab .submenu.active
{
background-color: #fff !important;
border-right: solid 1px #c3c3c3;
}

.tab .submenu
{
  border: solid 1px #fff;
}
.viewdata
{

  display: inline-table;

}

.modal {
 display: none;
  /* position: fixed;
  z-index: 993;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%; */
}
.modal_tables {
 display: none;
  /* position: fixed;
  z-index: 993;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%; */
}
.modal1 {
 display: none;
  /* position: fixed;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%; */
}
.modal2 {
 display: none;
  /* position: fixed;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%; */
}
.MuiMenu-paper
{
  z-index: 99999 !important;
}
.modal_content {
  background-color: white;
  /* position: absolute;
  width: 70%;
  height: 80%;
  left: 5%;
  top: 10%;
  border-radius: 10px;
  overflow: scroll; */
}
.model_html
{
  padding: 20px;
}
.model_html_login
{
  padding: 15%;
}
.close,.close1,.close2 {
  color: Black;
  float: right;
  padding: 0 10px;
  font-size: 20px;
  position: fixed;
  right: 5%;
}

.close:hover,.close1:hover,.close2:hover {
  color: #ccc;
  cursor: pointer;
}
.MuiOutlinedInput-input {
  padding: 7px 14px !important;
}
.poimap
{
  overflow: hidden;
}

.mytable .dx-datagrid-headers
{
  max-height: 0px;
  overflow: hidden;
}

.MuiTab-textColorInherit
{
  border: solid 1px #ccc;
}
.MuiTab-textColorInherit.Mui-selected
{
  background-color: #ccc;
}
.daylabel
{
  border:solid 1px #ccc;
  font-size:12px;
  text-align: center;
}
.attractionProviderDetail
{
  vertical-align: baseline;
  padding-top: 30px;
}
.labelsmall
{
  font-size:12px;
  color: #525252
  
}
.colorgreen
{
  font-size:12px;

  color: #AFCCD7
}
.divUpload
{
  width: 50%;
  padding: 5px 10px 5px 10px;
   border-radius: 5px;
  border:solid 1px #ccc;
}
.btnUpload 
{
  text-align: center;
   background-color: #c3c3c3;
    width: 110px;
   
 font-size: 12px;
}

.chipset
{
  display: flex;
  padding: 5px;
  margin-top: 10px;
}
.chip
{
  margin: 4px;
  display: flex;
  padding: 5px;
  border-radius: 10px;
  background-color: #E5E7EB;
}


.dropdownWithborder .MuiInput-root,.dropdownWithborder
{
  width: 100%;
  border:1px solid rgba(0, 0, 0, 0.42);
  
}
.dropdownWithborder .MuiSelect-select.MuiSelect-select
{
  padding:8px 10px;
}
.dropdownWithborder .MuiInput-underline:before
{
  border:none;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0px solid rgba(0, 0, 0, 0.87) !important;
}


/* .jodit-toolbar-collection_mode_vertical .jodit-ui-group, .jodit-toolbar-editor-collection_mode_vertical .jodit-ui-group
{
  background-color: #e0e0e0;
} */
.jodit-toolbar-editor-collection_mode_vertical .jodit-toolbar-button__button
{
  color: #9c9c9c;
}

.submitBtn
{
  background-color: #ACACAC !important;
  color:#ffffff !important;
}


.MuiFormGroup-root label
{
  padding: 0 !important;
}

.jodit-ui-button {
  background-color: #ccc;
}
.jodit-ui-button:hover {
  background-color: #007bff;
}

@media only screen and (max-width: 700px) {
  .tab {
   
    display: none;
}
.tabcontent {
 
  box-shadow: none;
}
.tabcontent1 {
 text-align: center;
  box-shadow: none;
}
td {
  display:inline-block;
  padding:5px;
  width:100%;
}
/* .MUIDataTable-responsiveBase-9
{
  max-width: 90vw;
  overflow: scroll;
} */
}

.chip-spaces {
  margin-bottom: 10px;
  margin-right: 2px;
}

.success-badge {
  background-color: #4caf50;
  color: white;
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
}

.pending-badge {
  background-color: #ffc107;
  color: #FFF;
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
}

.css-1q60rmi-MuiAutocomplete-endAdornment
{
  display: none !important;
}

.loading {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading__spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* PackagePricePax.css */
.price-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid black;
}

.price-table th, .price-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}


/* AdditionalServices.css */
.additional-services-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid black;
  margin-bottom: 20px;
}

.additional-services-table th,
.additional-services-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

.upgrade-car-header {
  text-align: center;
}
/* BasicAccommodation.css */
.basic-accommodation-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid black;
  margin-bottom: 20px;
}

.basic-accommodation-table th,
.basic-accommodation-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

/* BasicTransportation.css */
.basic-transportation-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid black;
  margin-bottom: 20px;
}

.basic-transportation-table th,
.basic-transportation-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

/* UpgradedAccommodation.css */
.upgraded-accommodation-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid black;
  margin-bottom: 20px;
}

.upgraded-accommodation-table th,
.upgraded-accommodation-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

/* UpgradedTransportation.css */
.upgraded-transportation-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid black;
  margin-bottom: 20px;
}

.upgraded-transportation-table th,
.upgraded-transportation-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}
/* AttractionsTable.css */
.attractions-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid black;
  margin-bottom: 20px;
}

.attractions-table th,
.attractions-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

.cancel-edit-btn {
  margin-right: 20px;
}

.editable_td {
  background-color: #4caf50;
}

.centerTitle{
  display: flex;
  justify-content: center;
  align-items: center;
}